import ContentLoader from "react-content-loader";

const NavBarShimmerUI = () => (
  <div
    className="d-flex justify-content-between align-items-center px-3"
    style={{ height: "80px", width: "100%", backgroundColor: "#1b1b1b" }}
  >
    <ContentLoader
      speed={2}
      width="100%"
      height="80px"
      backgroundColor="#2b2b2b"
      foregroundColor="#3a3a3a"
      viewBox="0 0 100% 80"
    >
      <circle cx="40" cy="40" r="30" />

      <rect x="100" y="25" rx="5" ry="5" width="70" height="20" />
      <rect x="180" y="25" rx="5" ry="5" width="80" height="20" />
      <rect x="270" y="25" rx="5" ry="5" width="100" height="20" />

      <rect x="450" y="10" rx="5" ry="5" width="300" height="60" />

      <rect x="800" y="25" rx="5" ry="5" width="80" height="20" />

      <rect x="900" y="25" rx="5" ry="5" width="50" height="20" />
    </ContentLoader>
  </div>
);

export default NavBarShimmerUI;
