import React, { useContext, useEffect, useRef, useState } from "react";
import { faUserMd, faBox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../black-logo.png";
import AuthContext from "../UseContext";

import useDebounce from "../customHooks/useDebounce";
import axios from "axios";
import NavBarShimmerUI from "./NavBarShimmerUI";

const Header: React.FC = () => {
  const [active, setActive] = useState<any>("book");
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<any>("");
  const [doctorsSearch, setDoctorsSearch] = useState<any>([]);
  const [customerSearch, setCustomerSearch] = useState<any>([]);
  const [packageSearch, setPackagesSearch] = useState<any>([]);
  const [categoriesSearch, setCategoriesSearch] = useState<any>([]);
  const [mobileSearch, setMobileSearch] = useState<any>(false);
  const [searchBar, setSearchBar] = useState<any>(true);
  const [menuOpen, setMenuOpen] = useState<any>(true);
  const [headerPuckData, setHeaderPuckData] = useState<any>([]);
  const [puckLoader, setPuckLoader] = useState<any>(false);
  const searchRef = useRef<any>(null);
  const debouncedFilterValue = useDebounce(searchValue, 500);
  const searchBarRef = useRef<HTMLInputElement>(null);
  let token: any = localStorage.getItem("token");

  useEffect(() => {
    getHeaderData();
  }, []);

  const activeNavbarClass = (type: any) => {
    setActive(type);
  };

  const getHeaderData = async () => {
    setPuckLoader(true);
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "LANDING PAGE",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setHeaderPuckData(data);
          setPuckLoader(false);
        } else {
          setHeaderPuckData([]);
          setPuckLoader(false);
        }
      })
      .catch((err) => {
        setPuckLoader(false);
        return err;
      });
  };

  const headerData: any = headerPuckData?.content?.find(
    (elem: any) => elem.type === "LandingPage"
  );

  const handleCloseSidebar = (route: any) => {
    const closeModalButton = document.querySelector(
      ".close-btn"
    ) as HTMLButtonElement | null;
    if (closeModalButton) {
      navigate(`/${route}`);
      closeModalButton.click();
    }
  };

  const handlePackage = () => {
    navigate("/packages");
  };

  const accessDashboard = () => {
    if (token) {
      window.location.href = "/app/#/dashboard";
      return;
    } else {
      window.location.href = "/app/login?type=register";
      return;
    }
  };

  useEffect(() => {
    if (searchValue !== "") {
      globalSearch(searchValue);
    }
  }, [debouncedFilterValue]);

  const globalSearch = async (value: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          search: value,
          action: "command",
          command: [
            {
              agent: "globalSearch",
              appName: "selfMaximized",
              folder: "search",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setDoctorsSearch(result?.data?.data?.doctors);
          setCustomerSearch(result?.data?.data?.customers);
          setPackagesSearch(result?.data?.data?.packages);
          setCategoriesSearch(result?.data?.data?.categories);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const showSearchList: any[] = [];

  if (
    doctorsSearch.length !== 0 ||
    customerSearch.length !== 0 ||
    packageSearch.length !== 0 ||
    categoriesSearch.length !== 0
  ) {
    if (doctorsSearch.length !== 0) {
      showSearchList.push(
        ...doctorsSearch.map((elem: any) => ({ ...elem, type: "doctors" }))
      );
    }
    if (customerSearch.length !== 0) {
      showSearchList.push(
        ...customerSearch.map((elem: any) => ({ ...elem, type: "customers" }))
      );
    }
    if (packageSearch.length !== 0) {
      showSearchList.push(
        ...packageSearch.map((elem: any) => ({ ...elem, type: "packages" }))
      );
    }
    if (categoriesSearch.length !== 0) {
      showSearchList.push(
        ...categoriesSearch.map((elem: any) => ({
          ...elem,
          type: "categories",
        }))
      );
    }
  }

  const goToThatPage = (elem: any, indexNo: any) => {
    setMobileSearch(false);

    navigate(
      elem.type === "packages"
        ? `/packages?categoryname=${elem.title}&categoryid=${elem.uuid}`
        : elem.type === "categories"
        ? `/categories/${elem.id}/${elem.uuid}`
        : elem.type === "doctors"
        ? `/buyproduct/${elem.userUUID}/0`
        : `/dashboard/users/userdetails/${elem.userId}`
    );
    setSearchValue("");
  };

  const handleGlobalSearch = (event: any) => {
    setSearchValue(event.target.value);
  };

  const openMobileSearch = () => {
    setMobileSearch(true);
  };

  const openSearchBar = () => {
    setSearchBar(!searchBar);
  };

  useEffect(() => {
    const handleClickOutside = async (event: any) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        searchRef.current &&
        !searchRef.current.contains(event.target)
      ) {
        setSearchBar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef]);

  const handleMenuClose = () => {
    setMenuOpen(!menuOpen);
  };

  return puckLoader ? (
    <NavBarShimmerUI />
  ) : (
    <>
      <header
        className="site-navbar site-navbar-target bg-white d-none d-lg-block py-2"
        role="banner"
      >
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <nav
                className="site-navigation text-right ml-auto "
                role="navigation"
              >
                <ul
                  className="site-menu main-menu js-clone-nav ml-auto"
                  id="mainLogin"
                >
                  <li className="dropdown header-logo">
                    <Link to={"/"} className="desktop-item">
                      <img
                        src={
                          headerData?.props?.sfLogo !== undefined
                            ? headerData?.props?.sfLogo
                            : logo
                        }
                        alt=""
                        className="img-fluid home--logo"
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                        loading="lazy"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      />
                    </Link>
                  </li>
                  <li className="login position-relative">
                    <Link to={"/categories"}>
                      {headerData?.props?.book
                        ? headerData?.props?.book
                        : "BOOK"}
                    </Link>
                    <ul className="drop drop-1 bg-white">
                      <div className="text-start">
                        <li className="login__subtitle">
                          <Link className="header_drop-menu" to={"categories"}>
                            {headerData?.props?.specialistMenu
                              ? headerData?.props?.specialistMenu
                              : "SPECIALIST"}
                          </Link>
                        </li>
                        <li className="login__subtitle" onClick={handlePackage}>
                          <span className="header_drop-menu d-block">
                            {headerData?.props?.packageMenu
                              ? headerData?.props?.packageMenu
                              : "PACKAGES"}
                          </span>
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li onClick={() => activeNavbarClass("journal")}>
                    <Link
                      to={"/journal"}
                      id="link_item"
                      className={`desktop-item ${
                        active === "journal" ? "active" : ""
                      }`}
                    >
                      {headerData?.props?.journal
                        ? headerData?.props?.journal
                        : "JOURNAL"}
                    </Link>
                  </li>
                  <li onClick={() => activeNavbarClass("about")}>
                    <Link
                      to={"about"}
                      id="link_item"
                      className={`desktop-item ${
                        active === "about" ? "active" : ""
                      }`}
                    >
                      {headerData?.props?.about
                        ? headerData?.props?.about
                        : "ABOUT"}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-6 desktop__header__right">
              <nav
                className="site-navigation text-left mr-auto "
                role="navigation"
              >
                <ul
                  className="site-menu main-menu js-clone-nav ml-auto"
                  id="mainLogin"
                >
                  <li className="dropdown-1">
                    <div className="dropdown-content-1">
                      <div className="searchbar">
                        <div
                          className="input-group custom__input--group mb-1"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {!searchBar && (
                            <input
                              className="form-control custom__form--control type--here"
                              type="search"
                              placeholder="SEARCH HERE..."
                              id="example-search-input"
                              ref={searchBarRef}
                              onChange={handleGlobalSearch}
                              value={searchValue}
                              autoComplete="off"
                              style={{
                                width: "auto",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                lineHeight: "40px",
                              }}
                            />
                          )}
                        </div>

                        <div
                          style={{
                            display: `${
                              searchValue &&
                              showSearchList.length !== 0 &&
                              !searchBar
                                ? "block"
                                : "none"
                            }`,
                          }}
                        >
                          {showSearchList.length !== 0 &&
                          showSearchList !== null ? (
                            showSearchList.map((elem: any, index: any) => (
                              <div
                                className="mb-1 d-flex justify-content-between"
                                key={index}
                                onClick={() => goToThatPage(elem, index)}
                              >
                                <span
                                  className="form-control custom__form--control"
                                  id="example-search-input"
                                >
                                  {elem?.name || elem?.title}
                                  <span className="d-block">
                                    - Result found in {elem.type}
                                  </span>
                                </span>
                              </div>
                            ))
                          ) : (
                            <span>No search results found</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    className="login pe-3 ps-3 position-relative"
                    onClick={openSearchBar}
                  >
                    {searchBar ? "SEARCH" : "CLOSE"}
                  </li>
                  <li
                    className="login pe-3 ps-3 position-relative"
                    onClick={accessDashboard}
                  >
                    {token ? "ACCOUNT" : "SIGN IN"}
                  </li>
                  <li>
                    <Link
                      to={"/cart"}
                      className="fa-solid fa-cart-shopping cursor_pointer pe-3 position-relative"
                    >
                      <span className="position-absolute top-0 translate-middle badge badge__bg">
                        {authContext?.cartLength === null
                          ? 0
                          : authContext?.cartLength}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/*  */}
      <header className={`d-block d-lg-none bg-white z_index `}>
        <nav>
          <div className="container-fluid px-0 z_index">
            <div className="wrapper py-5">
              <input type="radio" name="slider" id="menu-btn" />
              <input type="radio" name="slider" id="close-btn" />
              <ul className={`nav-links  mb-0`}>
                <label htmlFor="close-btn" className="btn close-btn">
                  <i className="fas fa-times"></i>
                </label>

                <li className="dropdown">
                  <Link
                    to={"/categories"}
                    className="d-flex justify-content-between"
                  >
                    <span className="letter_spacing" onClick={handleMenuClose}>
                      {headerData?.props?.book
                        ? headerData?.props?.book
                        : "BOOK"}
                    </span>
                    {menuOpen ? (
                      <span className="text-white" onClick={handleMenuClose}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-chevron-compact-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-white" onClick={handleMenuClose}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-chevron-compact-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
                          />
                        </svg>
                      </span>
                    )}
                  </Link>
                  {!menuOpen && (
                    <div className="dropdown-container">
                      <Link
                        to={"categories"}
                        onClick={() => handleCloseSidebar("categories")}
                      >
                        <span className="ps-6 letter_spacing">
                          <FontAwesomeIcon icon={faUserMd} className="pe-2" />
                          {headerData?.props?.specialistMenu
                            ? headerData?.props?.specialistMenu
                            : "SPECIALIST"}
                        </span>
                      </Link>
                      <Link
                        to={"packages"}
                        onClick={() => {
                          handleCloseSidebar("packages");
                        }}
                      >
                        <span className="ps-6 letter_spacing">
                          <FontAwesomeIcon icon={faBox} className="pe-2" />
                          {headerData?.props?.packageMenu
                            ? headerData?.props?.packageMenu
                            : "PACKAGE"}
                        </span>
                      </Link>
                    </div>
                  )}
                </li>
                <li
                  onClick={() => handleCloseSidebar("journal")}
                  className="d-flex justify-content-between letter_spacing"
                >
                  <Link to={"journal"}>
                    {" "}
                    {headerData?.props?.journal
                      ? headerData?.props?.journal
                      : "JOURNAL"}
                  </Link>
                </li>
                <li
                  onClick={() => handleCloseSidebar("about")}
                  className="letter_spacing"
                >
                  <Link to={"about"}>
                    {" "}
                    {headerData?.props?.about
                      ? headerData?.props?.about
                      : "ABOUT"}
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    to={"/"}
                    onClick={accessDashboard}
                    className="letter_spacing"
                  >
                    {token ? "ACCOUNT" : "SIGN IN"}
                  </Link>
                </li>
              </ul>
              <label htmlFor="menu-btn" className="btn menu-btn text-dark mb-0">
                <i className="fas fa-bars"></i>
              </label>
              <div className="d-flex justify-content-center">
                <Link to={"/"} className="desktop-item">
                  <img
                    src={
                      headerData?.props?.sfLogo !== undefined
                        ? headerData?.props?.sfLogo
                        : logo
                    }
                    alt=""
                    className="img-fluid home--logo"
                    style={{
                      width: "80px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <div className="search-container">
                    <form action="/search" method="get">
                      <label
                        className="button searchbutton customsearchbutton "
                        htmlFor="searchright"
                        onClick={openMobileSearch}
                      >
                        <i
                          className="fa-solid fa-magnifying-glass me-2"
                          style={{
                            height: "28px",
                            color: "#000000",
                            fontSize: "18px",
                            top: "40px",
                            position: "relative",
                            right: "14px",
                          }}
                        ></i>
                      </label>
                    </form>
                  </div>
                  {mobileSearch ? (
                    <div id="mobileSearch">
                      <span className="d-flex justify-content-between position-relative">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={handleGlobalSearch}
                          value={searchValue}
                          autoComplete="off"
                          className="form-control"
                        />
                        <button
                          type="button"
                          className="btn-close position-absolute"
                          onClick={() => {
                            setMobileSearch(false);
                            setSearchValue("");
                          }}
                          style={{
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        ></button>
                      </span>
                    </div>
                  ) : null}
                  <div
                    className="mobile__search__output"
                    style={{
                      display: `${
                        searchValue && showSearchList.length !== 0
                          ? "block"
                          : "none"
                      }`,
                      top: "89px",
                      width: "100%",
                    }}
                  >
                    {showSearchList.length !== 0 && showSearchList !== null ? (
                      showSearchList.map((elem: any, index: any) => (
                        <>
                          <div
                            className="p-2"
                            key={index}
                            onClick={() => goToThatPage(elem, index)}
                          >
                            <p className="mb-0 mobile__output">
                              {elem?.name || elem?.title}
                            </p>
                            <p className="mb-0 mobile__output">
                              - Result found in {elem?.type}
                            </p>
                          </div>
                          <p
                            style={{ borderBottom: "1px solid black" }}
                            className="mb-0"
                          ></p>
                        </>
                      ))
                    ) : (
                      <span>No search results found</span>
                    )}
                  </div>
                </div>
                <div className="cart__icon__card">
                  <Link
                    to={"/cart"}
                    className="fa-solid fa-cart-shopping text-dark cursor_pointer pe-3 position-relative"
                  >
                    <span className="position-absolute top-0 translate-middle badge badge__bg">
                      {authContext?.cartLength === null
                        ? 0
                        : authContext?.cartLength}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
