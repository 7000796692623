import axios from "axios";
import React, { useEffect, useState } from "react";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState("");

  const toggleAccordion = (index: any) => {
    setActiveIndex(activeIndex === index ? "" : index);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [faqList, setFaqList] = useState<any>([]);

  useEffect(() => {
    getfaqList();
  }, []);
  const getfaqList = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        isActive: true,
        menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
        action: "command",
        command: [
          {
            agent: "faqList",
            appName: "selfMaximized",
            folder: "faq",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setFaqList(result?.data?.data);
        } else {
          setFaqList([]);
        }
      });
  };

  return (
    <section className="pb-0 faq-wrapper pt-0">
      <div className="container-fluid left-right-space">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {faqList.length !== 0 && faqList !== null ? (
                faqList.map((elem: any, index: any) => (
                  <div className="accordion-item my-3" key={elem.id}>
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className={`accordion-button py-1 ${
                          activeIndex === index ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={activeIndex === index}
                        aria-controls="flush-collapseOne"
                      >
                        {elem.title}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className={`accordion-collapse collapse ${
                        activeIndex === index ? "show" : ""
                      }`}
                      aria-labelledby="flush-headingOne"
                    >
                      <div className="accordion-body text-uppercase bg-white pt-0">
                        {elem.description}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span>No faq list available</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
