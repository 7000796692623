import React, { useContext } from "react";
import { Link, useNavigate, redirect } from "react-router-dom";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthContext from "../UseContext";
import moment from "moment-timezone";

const Cart: React.FC = () => {
  const [orderId, setOrderId] = useState<any>("");
  const [cartList, setCartList] = useState<any>([]);
  const [totalPrice, setTotalPrice] = useState<any>([]);
  const [cartPuckData, setCartPuckData] = useState<any>([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const params: any = new URLSearchParams(window.location.search);
  const lastactivecartparams = params.get("lastactivecart");
  const [userTimezone, setUserTimezone] = useState<any>("");

  useEffect(() => {
    if (lastactivecartparams) {
      toast.info("YOU STILL HAVE ITEMS PRESENT IN YOUR CART", {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="60"
            height="60"
            viewBox="0 0 30 30"
          >
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
          </svg>
        ),
        className: "custom-toast",
        bodyClassName: "custom-toast",
      });
    }
  }, [lastactivecartparams]);

  const authContext = useContext(AuthContext);
  let data = localStorage.getItem("userInfo");
  let userInfo: any = null;
  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }
  let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;
  let cartId: any = localStorage.getItem("cartId");
  const Swal = require("sweetalert2");

  useEffect(() => {
    getCartData();
  }, []);

  const createOrder = async () => {
    if (!token && !orderId) {
      return (window.location.href = "app/login?callback=userregister");
    }
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        userId: userInfo ? userInfo.id : null,
        cartId:
          lastActiveCartId !== null && lastActiveCartId !== undefined
            ? lastActiveCartId
            : cartId,
        action: "command",
        command: [
          {
            agent: "createUser",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      })
      .then(async (result) => {
        if (result?.data?.statusCode === 200) {
          await axios
            .post(
              `${process.env.REACT_APP_API}/pipeline`,
              {
                successurl: `${process.env.REACT_APP_LOCALHOST_URL}/success/${cartId}`,
                cancelurl: `${process.env.REACT_APP_LOCALHOST_URL}/cancel`,
                cartUuid:
                  lastActiveCartId !== null && lastActiveCartId !== undefined
                    ? lastActiveCartId
                    : cartId,
                organizationId: 1,
                action: "command",
                command: [
                  {
                    agent: "createOrder",
                    appName: "selfMaximized",
                    folder: "order",
                  },
                ],
              },
              {
                headers: { "x-access-token": token },
              }
            )
            .then((result) => {
              if (result?.data?.code === 1) {
                setOrderId(result?.data?.data?.orderID);
                navigate(`/checkout/${result?.data?.data?.orderID}`);
              } else {
                return false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCartList = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        cartUuid:
          lastActiveCartId !== null && lastActiveCartId !== undefined
            ? lastActiveCartId
            : cartId,
        organizationId: 1,
        action: "command",
        command: [
          {
            agent: "cartList",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      })
      .then((result) => {
        setCartList(result?.data?.data);
        setUserTimezone(result?.data?.data[0]?.timezone);
        //Context API
        authContext.cart(cartList.length);

        const totalPriceOfItems = result?.data?.data;
        if (totalPriceOfItems) {
          const totalPrice = totalPriceOfItems.reduce(
            (accumulator: number, elem: any) => {
              return accumulator + Number(elem.price);
            },
            0
          );
          setTotalPrice(totalPrice);
        } else {
          setTotalPrice(0); // or whatever default value you want to set if the data is not available
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCartItem = async (elem: any) => {
    if (token) {
      Swal.fire({
        title: "ARE YOU SURE YOU WANT TO DELETE THIS PRODUCT?",
        text: "YOU WON'T BE ABLE TO REVERT THIS!",
        icon: "warning",
        iconColor: "black", // Set the exclamation mark color to black
        background: "#fff", // Set background to black
        color: "#000", // Set text color to white
        showCancelButton: true,
        confirmButtonColor: "#fff", // White button for "Yes, delete it!"
        cancelButtonColor: "#000", // Black button for "Cancel"
        confirmButtonText: "YES, DELETE IT!",
        cancelButtonText: "CANCEL",
        customClass: {
          confirmButton: "confirm-button-custom",
          cancelButton: "cancel-button-custom",
          title: "title-class",
          text: "text-class", // Custom class for confirm button
        },
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          await axios
            .post(
              `${process.env.REACT_APP_API}/pipeline`,
              {
                cartUuid:
                  lastActiveCartId !== undefined && lastActiveCartId !== null
                    ? lastActiveCartId
                    : cartId,
                productId: elem.cartProductId,
                action: "command",
                command: [
                  {
                    agent: "deleteCartItem",
                    appName: "selfMaximized",
                    folder: "order",
                  },
                ],
              },
              {
                headers: { "x-access-token": token },
              }
            )
            .then((result) => {
              if (result?.data?.status === "success") {
                toast.success("ITEM DELETED SUCCESSFULLY!", {
                  icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="60"
                      height="60"
                      viewBox="0 0 30 30"
                    >
                      <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
                    </svg>
                  ),
                  className: "custom-toast",
                  bodyClassName: "custom-toast",
                });
                getCartList();
              }
            })
            .catch((err) => {
              return toast.error("Something went wrong" + err);
            });
          Swal.fire({
            title: "DELETED!",
            text: "YOUR PRODUCT IS DELETED.",
            icon: "success",
            iconColor: "black",
            background: "#fff", // Black background for success message
            color: "#000", // White text for success message
            customClass: {
              title: "title-class2",
              text: "text-class2",
              confirmButton: "ok-button-custom", // Custom class for confirm button
            },
          });
        }
      });
    } else {
      window.location.href = "app/login?callback2=deletecart";
    }
  };

  const getCartListCb = useCallback(getCartList, [
    cartId,
    cartList?.length,
    lastActiveCartId,
  ]);

  useEffect(() => {
    getCartListCb();
  }, [getCartListCb]);

  const getCartData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "CART",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setCartPuckData(data);
        } else {
          setCartPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const cartData: any = cartPuckData?.content?.find(
    (elem: any) => elem.type === "Cart"
  );

  function getTimeDifference(startTime: string, endTime: string): number {
    // Sanitize the input by replacing invalid "000Z" with "00Z"
    const sanitizedStartTime = startTime.replace(/:000Z$/, ":00Z");
    const sanitizedEndTime = endTime.replace(/:000Z$/, ":00Z");

    // Parse the sanitized time strings into Date objects
    const startDate = new Date(sanitizedStartTime);
    const endDate = new Date(sanitizedEndTime);

    // Validate parsed dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error("Invalid date format:", { startTime, endTime });
      return NaN;
    }

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to minutes
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    return differenceInMinutes;
  }

  return (
    <>
      <section className="custom-pt-1 custom-pt-1-new custom-pb-2 parallaxie position-relative journal-detail-page-wrapper h-100">
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner detail-page-banner-inner-new">
                <h1 className="mb-0 banner-heading">
                  {cartData?.props?.title ? cartData?.props?.title : "CART"}
                </h1>
                <p className="mb-0 banner-description">
                  {cartData?.props?.description
                    ? cartData?.props?.description
                    : "THE LIST IS WHAT IS KNOWN AS A DYNAMIC SIZE ARRAY SINCE ITS SIZE GROWS AS MORE DATA IS ADDED TO IT, NEGATING THE NEED TO PRE-DEFINE A STATIC SIZE FOR THE LIST"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="page-content">
        <section
          className="cart__page__wrapper py-0 cart__product__quantity__total position-relative 456789 d-lg-block
        "
        >
          <div className="container-fluid left-right-space left-right-space-new">
            {cartList?.length !== 0 &&
            cartList !== null &&
            cartList !== undefined ? (
              cartList?.map((elem: any) => (
                <div>
                  <div
                    className="row d-flex align-items-start custom-border"
                    key={elem.id}
                  >
                    <div className="col-lg-7 col-md-7 pb-4 pb-lg-0">
                      <h2 className="mb-0 products__text text-white pb-3">
                        PRODUCTS
                      </h2>
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-3 col-md-4 col-sm-4">
                          <div className="package__image__card">
                            <Link to={`/buyproduct/${elem.userUUID}/0`}>
                              <img
                                src={elem?.specialist_profile || elem?.image}
                                alt=""
                                className="img-fluid w-100 package__image__card__img"
                                style={{ width: "auto", height: "auto" }}
                                loading="lazy"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-8">
                          <div className="d-lg-block pt-4 pt-lg-0 pt-lg-0">
                            <h3 className="mb-0 package__title text-white mb-0 text-uppercase">
                              {elem.specialist_name?.toUpperCase()}
                            </h3>
                            <p className="mb-0 package__description pb-0 mb-0">
                              {elem?.appnt_type?.toUpperCase()}
                            </p>
                            <h3 className="text-white mb-0 pb-0 custom-letter-spacing text-uppercase">
                              {elem?.title?.toUpperCase()}
                            </h3>
                            {elem?.book_status === 1 ? (
                              <>
                                <p className="text-white mb-0 pb-0 custom-letter-spacing text-uppercase">
                                  SELECTED DATE -{" "}
                                  {elem?.appointment_date?.toUpperCase()}
                                </p>
                                <p className="text-white mb-0 pb-0 custom-letter-spacing text-uppercase">
                                  SELECTED SLOT -{" "}
                                  {userTimezone && elem?.start_time
                                    ? moment
                                        .tz(
                                          elem.start_time.replace(
                                            /:000Z$/,
                                            ":00Z"
                                          ),
                                          "UTC"
                                        )
                                        .tz(userTimezone)
                                        .format("HH:mm A")
                                    : "Date Not Available"}
                                  <span className="ps-2 pe-2">TO</span>
                                  {userTimezone && elem?.end_time
                                    ? moment
                                        .tz(
                                          elem.end_time.replace(
                                            /:000Z$/,
                                            ":00Z"
                                          ),
                                          "UTC"
                                        )
                                        .tz(userTimezone)
                                        .format("HH:mm A")
                                    : "Date Not Available"}
                                  <div>
                                    <span className="pe-2">DURATION -</span>
                                    {getTimeDifference(
                                      elem?.start_time,
                                      elem?.end_time
                                    )}
                                    MIN{" "}
                                  </div>
                                </p>
                                <p className="text-white mb-0 pb-0 custom-letter-spacing text-uppercase">
                                  MODE - {elem?.appnt_mode}
                                </p>
                              </>
                            ) : (
                              !elem?.title && (
                                <span className="text-white letter_spacing">
                                  NOTE - YOU WOULD BE ABLE TO SELECT SLOT LATER
                                  FROM YOUR ACCOUNT
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5">
                      <h2 className="mb-0 products__text text-white pb-3">
                        TOTAL
                      </h2>
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-7">
                          <p className="amount mb-0 text-center add__cart__amount abount-box-main text-white">
                            <span className="dollar__symbol text-white">$</span>
                            <span className="amt ps-2 text-white">
                              {elem.price}
                            </span>
                          </p>
                        </div>
                        <div className="col-lg-5 col-md-5 text-left text-lg-end text-md-end mt-3 mt-lg-0 mt-md-0">
                          <div className="total__card">
                            <button
                              className="procced__to__buy procced__to__buy-new"
                              onClick={() => deleteCartItem(elem)}
                            >
                              DELETE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row d-flex align-items-start custom-border"
                    key={elem.id}
                  >
                    <div className="col-lg-7 col-md-7 pb-4 pb-lg-0">
                      <div className="row d-flex align-items-center"></div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-12 text-center">
                <span className="empty__cart__error__message text-white text-uppercase">
                  Cart is empty
                </span>
              </div>
            )}
          </div>
        </section>

        {cartList?.length !== 0 &&
        cartList !== null &&
        cartList !== undefined ? (
          <section className="continute__to__exploring position-relative py-4">
            <div className="container-fluid left-right-space left-right-space-new">
              <div className="row d-flex align-items-center">
                <div className="col-lg-6 pb-4 pb-lg-0 order-2 order-md-1">
                  <Link to={"/categories"}>
                    <h3 className="continue__to__exploring">
                      CONTINUE TO EXPLORE
                    </h3>
                  </Link>
                </div>
                <div className="col-lg-6 text-start text-lg-end order-1 order-md-2">
                  <p className="mb-0 d-flex justify-content-start justify-content-lg-end align-items-center pb-5 pb-lg-0">
                    <span className="subtotal--text pe-2 text-white">
                      SUBTOTAL
                    </span>
                    <span className="subtotal--dollar pe-2">$</span>
                    <span className="subtotal--amount">{totalPrice}</span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-start text-lg-end">
                  <button className="procced__to__buy" onClick={createOrder}>
                    CHECKOUT
                  </button>
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </div>
    </>
  );
};
export default Cart;
