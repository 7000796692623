import ContentLoader from "react-content-loader";

const ShimmerUI = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{ height: "100vh", width: "100vw", backgroundColor: "#1b1b1b" }}
  >
    <ContentLoader
      speed={2}
      width="100%"
      height="100vh"
      viewBox="0 0 100% 100%"
      backgroundColor="#2b2b2b"
      foregroundColor="#3a3a3a"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="80%" />

      <rect x="0" y="82%" rx="5" ry="15" width="80%" height="5%" />

      <rect x="0" y="89%" rx="5" ry="15" width="100%" height="80%" />
    </ContentLoader>
  </div>
);

export default ShimmerUI;
