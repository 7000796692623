import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AllBlog: React.FC = () => {
  const [allBlogsData, setAllBlogs] = useState<any>([]);
  const [newBlogData, setNewBlogData] = useState<any>([]);
  const [page, setPage] = useState<any>(1);
  const [grid, setGrid] = useState<any>(3);
  const [loader, setLoader] = useState<any>(false);
  const [firstTimeLoader, setFirstTimeLoader] = useState<any>(false);
  const [categoriesPuckData, setCategoriesPuckData] = useState<any>([]);

  useEffect(() => {
    getCategoriesPuckData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllBlogs = async (pageNo: any) => {
    setLoader(true);
    if (pageNo === 1) {
      setFirstTimeLoader(true);
    }
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: (pageNo - 1) * 4,
            limit: 4,
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "categoryLists",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        const newData = result?.data?.data;
        setNewBlogData(newData);
        setAllBlogs((prevData: any) => [...prevData, ...newData]);
        setLoader(false);
        setFirstTimeLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setFirstTimeLoader(false);
      });
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - scrollTop) {
      setLoader(true);
    }
  };

  const getMoreBlogs = () => {
    if (newBlogData.length !== 0) {
      setTimeout(() => {
        setPage((pageNo: any) => pageNo + 1);
        getAllBlogs(page + 1);
      }, 300);
    } else {
      setLoader(false);
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getBlogsCB = useCallback(() => {
    getAllBlogs(1);
  }, []);

  useEffect(() => {
    getBlogsCB();
  }, [getBlogsCB]);

  const getMoreBlogsCB = useCallback(() => {
    getMoreBlogs();
  }, [loader]);

  useEffect(() => {
    if (!loader) return;
    getMoreBlogsCB();
  }, [loader, getMoreBlogsCB]);

  const gridColumn = (number: any) => {
    setGrid(number);
  };

  const getCategoriesPuckData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "CATEGORIES",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setCategoriesPuckData(data);
        } else {
          setCategoriesPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const catPuckData: any = categoriesPuckData?.content?.find(
    (elem: any) => elem.type === "Categories"
  );

  return (
    <>
      <section
        className="custom-pt-1 detail-page-banner parallaxie position-relative journal-detail-page-wrapper h-100"
        data-overlay="6"
      >
        <div className="container-fluid position-relative">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner detail-page-banner-inner-one">
                <h1 className="mb-0 banner-heading mb-4 mb-md-0">
                  {catPuckData?.props?.title
                    ? catPuckData?.props?.title
                    : "EXPLORE WELLNESS CATEGORIES"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page-content">
        <section className="py-0 pb-5 mb-2 get-yourself">
          <div className="container-fluid left-right-space">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <p className="mb-0 banner-description text-uppercase">
                  {catPuckData?.props?.description
                    ? catPuckData?.props?.description
                    : ` Get yourself out there explore the best with us lets us know
                  you`}
                </p>
              </div>
              <div className="col-lg-6 d-flex justify-content-end align-items-center">
                <div className="view-all-btn-wrapper d-flex">
                  {/* <a href="#" className="me-3 explore-view">
                    VIEW
                  </a> */}
                  <div className="grid-icos">
                    <img
                      src="./assets/images/icon/Two-grid.png"
                      alt="two grid"
                      className="img-fluid me-2 cursor_pointer"
                      style={{ width: "20px", height: "20px" }}
                      loading="lazy"
                      onClick={() => gridColumn(6)}
                    />
                    <img
                      src="./assets/images/icon/four-grid.png"
                      alt="two grid"
                      className="img-fluid cursor_pointer"
                      style={{ width: "20px", height: "20px" }}
                      loading="lazy"
                      onClick={() => gridColumn(3)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="explore-wrapper py-0">
          <div className="container-fluid">
            <div className="row position-relative  ">
              {firstTimeLoader ? (
                <div className="col-12">
                  <div className="mx-auto d-flex align-items-center justify-content-center">
                    <div className="ml-loader ml-loader--center">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : (
                allBlogsData.map((elem: any) => (
                  <div
                    className={`col-lg-${grid} position-relative px-0`}
                    key={elem.id}
                  >
                    <Link
                      to={`/categories/${elem?.id}/${elem?.uuid}`}
                      className="explore-more-wrapper"
                      key={elem.id}
                    >
                      <div className="explore-thumb">
                        <div className="">
                          <img
                            src={elem.image}
                            style={{
                              height: `${grid === 6 ? "500px" : "300px"}`,
                              minHeight: `${grid === 6 ? "500px" : "300px"}`,
                              overflow: "hidden",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            alt="pregency"
                            className="w-100"
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div className="explore-content">
                        <h3 className="explore-heading mb-0">{elem.name}</h3>
                      </div>
                    </Link>
                  </div>
                ))
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AllBlog;
