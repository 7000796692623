import axios from "axios";
import React, { useEffect, useState } from "react";
import ShimmerUI from "../Shimmer";

const withTitle = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const [faqPuckData, setFaqPuckData] = useState<any>([]);
    const [puckLoader, setPuckLoader] = useState<any>(false);

    const getFaqPuckData = async () => {
      setPuckLoader(true);
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          name: "FAQ",
          action: "command",
          menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
          command: [
            {
              agent: "pageByName",
              appName: "selfMaximized",
              folder: "pages",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            const data = JSON.parse(result?.data?.data?.response[0]?.data);
            setFaqPuckData(data);
            setPuckLoader(false);
          } else {
            setFaqPuckData([]);
            setPuckLoader(false);
          }
        })
        .catch((err) => {
          setPuckLoader(false);
          return err;
        });
    };

    const faqData = faqPuckData[0]?.content?.find(
      (elem: any) => elem.type === "Faq"
    );

    useEffect(() => {
      getFaqPuckData();
    }, []);

    const { title } = props;
    return puckLoader ? (
      <ShimmerUI />
    ) : (
      <section className="pb-0 faq-wrapper">
        <div className="container-fluid left-right-space">
          <div className="row py-4">
            <div className="col-lg-12">
              <h1 className="text-center mb-md-0">
                {faqData?.props?.title ? faqData?.props?.title : title}
              </h1>
            </div>
          </div>
          <WrappedComponent {...props} />
        </div>
      </section>
    );
  };
};

export default withTitle;
