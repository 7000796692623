import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ShimmerUI from "./Shimmer";

const TermsAndCondition = () => {
  const [termsAndConditions, setTermsAndCondition] = useState<any>([]);
  const [termsAndConditionsPuckData, setTermsAndConditionsPuckData] =
    useState<any>([]);
  // const [policies, setPolicies] = useState<any>([]);
  const [puckLoader, setPuckLoader] = useState<any>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPolicies();
    getTermsAndConditionsPuckData();
  }, []);

  const getPolicies = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
        action: "command",
        command: [
          {
            agent: "getTermsAndConditions",
            appName: "selfMaximized",
            folder: "policies",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          // setPolicies(result?.data?.data);
          setTermsAndCondition(
            result?.data?.data?.filter(
              (item: any) => item.pageName === "Terms And Conditions"
            )
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong", err);
      });
  };

  interface htmlContent {
    htmlContent: any;
  }
  const HtmlToPoints: React.FC<htmlContent> = ({ htmlContent }) => {
    // Split paragraphs and line breaks
    const sections = htmlContent
      .split(/<\/?p>|<br\s*\/?>|<\/?b>/)
      .filter((section: any) => section.trim() !== "");

    return (
      <div>
        <ol className="">
          {sections.map((section: any, index: any) => (
            <li
              key={index}
              dangerouslySetInnerHTML={{ __html: section }}
              className="mb-3 policies-list"
            />
          ))}
        </ol>
      </div>
    );
  };

  const getTermsAndConditionsPuckData = async () => {
    setPuckLoader(true);
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "TERMS AND CONDITIONS",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setTermsAndConditionsPuckData(data);
          setPuckLoader(false);
        } else {
          setTermsAndConditionsPuckData([]);
          setPuckLoader(false);
        }
      })
      .catch((err) => {
        setPuckLoader(false);
        return err;
      });
  };

  const termsandconditionsData: any = termsAndConditionsPuckData?.content?.find(
    (elem: any) => elem.type === "TermsAndConditions"
  );

  return puckLoader ? (
    <ShimmerUI />
  ) : (
    <>
      <section className="pb-0 user-registration-flow-wrapper contact-us_wrapper_title">
        <div className="container-fluid">
          <div className="row py-4">
            <div className="col-lg-12">
              <h1 className="user-registration-title mb-0 text-center">
                {termsandconditionsData
                  ? termsandconditionsData?.props?.title
                  : ""}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0 user-registration-flow-wrapper py-0">
        <div className="container-fluid left-right-space">
          <div className="row">
            <div className="col-lg-12 pb-5">
              <h4
                className="text-uppercase text-white text-start mb-5 ms-5"
                style={{
                  fontSize: "35px",
                  color: " #fff",
                  letterSpacing: "4px",
                  lineHeight: "19px",
                }}
              >
                {termsandconditionsData
                  ? termsandconditionsData?.props?.policyForCustomer
                  : ""}
              </h4>
              <p className="mb-0 banner-description text-uppercase">
                {termsAndConditions.length !== 0 ? (
                  <HtmlToPoints
                    htmlContent={termsAndConditions[0].pageDataUser}
                  />
                ) : null}
              </p>
            </div>
            <div className="col-lg-12 pb-5">
              <h4
                className="text-uppercase text-white text-start mb-5 ms-5"
                style={{
                  fontSize: "35px",
                  color: " #fff",
                  letterSpacing: "4px",
                  lineHeight: "19px",
                }}
              >
                {termsandconditionsData
                  ? termsandconditionsData?.props?.policyForSpecialist
                  : ""}
              </h4>
              <p className="mb-0 banner-description text-uppercase">
                {termsAndConditions.length !== 0 ? (
                  <HtmlToPoints
                    htmlContent={termsAndConditions[0].pageDataSpecialist}
                  />
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;
