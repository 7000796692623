import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import bookSpecialistImage from "../../../web/src/images/specialist.jpg";
import bookPackageImage from "../../../web/src/images/packages.png";
import AuthContext from "../UseContext";
import bannerImg from "../images/new-img.png";
import { toast } from "react-toastify";
import LandingPopUpLogo from "../../src/images/logos/WHITELOGONOBG.png";
import ShimmerUI from "./Shimmer";
import Faq from "./Faq";

const Book: React.FC = () => {
  const [journalData, setJournalData] = useState<any>([]);
  const authContext = useContext(AuthContext);
  const [fixedText, setFixedText] = useState<any>("");
  const [fixedText2, setFixedText2] = useState<any>("SELF MAXIMIZED"); // Initial fixed text
  const [isScrolled, setIsScrolled] = useState<any>(false);
  const [scrollPositionNo, setScrollPosition] = useState<any>(0);
  const [popUpPuckData, setPopUpPuckData] = useState<any>([]);
  const [puckData, setPuckData] = useState<any>([]);
  const [puckloader, setPuckLoader] = useState<any>(false);
  const [hoverForSpecialist, setHoverForSpecialist] = useState<any>(false);
  const [hoverForPackage, setHoverForPackage] = useState<any>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getPuckData();
    getJournals();
    getPopUpPuckData();
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const getPopUpPuckData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "POP UP",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setPopUpPuckData(data);
        } else {
          setPopUpPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const popUp = popUpPuckData?.content?.find(
    (elem: any) => elem.type === "PopUp"
  );

  const getPuckData = async () => {
    setPuckLoader(true);
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "LANDING PAGE",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const data = JSON.parse(res?.data?.data?.response[0]?.data);
          setPuckData(data);
          setPuckLoader(false);
        } else {
          setPuckData(null);
          setPuckLoader(false);
        }
      })
      .catch((err) => {
        setPuckLoader(false);
        return err;
      });
  };

  const getJournals = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 4,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "blogList",
            appName: "selfMaximized",
            folder: "blogs",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setJournalData(result?.data?.data);
        } else {
          setJournalData([]);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const landingPage: any = puckData?.content?.find(
    (item: any) => item.type === "LandingPage"
  );

  const bookSpecialist = {
    backgroundImage: `url(${
      landingPage?.props?.bookSpecImage || bookSpecialistImage
    })`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e4e4e4",
  };

  const bookImages = {
    backgroundImage: `url(${
      landingPage?.props?.bookPackImg || bookPackageImage
    })`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let data = localStorage.getItem("userInfo");
  let userInfo = null;
  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }
  let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;
  let cartId = localStorage.getItem("cartId");

  const getCartList = async () => {
    let token = localStorage.getItem("token");

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          cartUuid:
            lastActiveCartId !== null && lastActiveCartId
              ? lastActiveCartId
              : cartId,
          organizationId: 1,
          action: "command",
          command: [
            {
              agent: "cartList",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          authContext.cart(result?.data?.data.length);
        } else {
          return result?.data?.message;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCartList();
  }, []);

  const aboutRef = useRef<HTMLDivElement | null>(null);
  const specialistRef = useRef<HTMLDivElement | null>(null);
  const faqRef = useRef<HTMLDivElement | null>(null);
  const journalRef = useRef<HTMLDivElement | null>(null);
  const bookSpecialistSection = useRef<HTMLDivElement | null>(null);
  const bookSpecialistRef = useRef<HTMLDivElement | null>(null);
  const bookPackageRef = useRef<HTMLDivElement | null>(null);

  const sectionRefs = [
    { ref: aboutRef, text: "ABOUT US" },
    { ref: journalRef, text: "JOURNAL" },
    { ref: specialistRef, text: "BECOME A SPECIALIST" },
    { ref: faqRef, text: "FAQ" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      sectionRefs.forEach(({ ref, text }) => {
        const element = ref.current;
        if (element) {
          const { offsetTop, offsetHeight } = element;

          // Adjust scroll detection for the journal section
          let adjustedOffsetTop = offsetTop;
          if (text === "JOURNAL") {
            adjustedOffsetTop = offsetTop + 100;
          }
          if (text === "FAQ") {
            adjustedOffsetTop = offsetTop + 200;
          }

          // Ensure the section is considered active when the viewport midpoint is within the section
          const isVisible =
            scrollPosition >= adjustedOffsetTop - offsetHeight * 0.3 &&
            scrollPosition < adjustedOffsetTop + offsetHeight * 0.7;

          if (isVisible) {
            setFixedText(text);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs]);

  useEffect(() => {
    // Scroll-based text update
    const handleScroll = () => {
      const mobileView = window.innerWidth <= 768;
      const scrollPosition = window.scrollY;
      console.log(scrollPosition);
      setScrollPosition(scrollPosition);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handlePopUp = () => {
      let modal: any;

      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        modal = document.getElementById("popUpModal");
        if (modal) {
          modal.click();
        }
      }
    };

    window.addEventListener("scroll", handlePopUp);

    return () => {
      window.removeEventListener("scroll", handlePopUp);
    };
  }, []);

  const bookSpecialistText =
    landingPage && landingPage?.props?.bookSpecialistText;
  const bookPackageText = landingPage && landingPage?.props?.bookPackageText;

  return puckloader ? (
    <ShimmerUI />
  ) : (
    <>
      <div
        className={`custom-pt-1 custom-pb-2 custom-pt-1-1 parallaxie position-relative book-specialist-section global-wellness__reimagined new-custom-pt-1 ${
          isScrolled ? "scrolled" : ""
        }`}
        ref={bookSpecialistSection}
        style={{
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${
            landingPage?.props?.bannerImg
              ? landingPage?.props?.bannerImg
              : bannerImg
          })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: scrollPositionNo === 0 ? 0 : "5",
        }}
        data-overlay="6"
        id="home__banner1"
      >
        <div className="container-fluid-1 left-right-space d-flex justify-content-center align-items-center h-100 mt-lg-5 pt-lg-5">
          <div className="row justify-content-center text-center align-items-center">
            <div className="col-12 col-lg-12 col-xl-12 ">
              <div className="logo pt-5 d-flex justify-content-center align-items-center">
                {window.innerWidth <= 768 &&
                scrollPositionNo > 400 &&
                scrollPositionNo < 1582 ? (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="position-fixed"
                        onMouseEnter={() => setHoverForSpecialist(true)}
                        onMouseLeave={() => setHoverForSpecialist(false)}
                        style={{ margin: "auto", zIndex: "999999999999999" }}
                      >
                        {window.innerWidth <= 768 &&
                        scrollPositionNo >= 472 &&
                        scrollPositionNo <= 913 ? (
                          <Link
                            to={"/categories"}
                            className={`split__screen--link mb-0 font-bold `}
                            style={{
                              fontSize: "72px",
                              lineHeight: "86.2px",
                              color: landingPage?.props?.bookSpecialistTextColor
                                ? landingPage?.props?.bookSpecialistTextColor
                                : "white",
                              letterSpacing: "5px",
                            }}
                          >
                            {bookSpecialistText
                              ? bookSpecialistText.split(" ")[0]
                              : "BOOK"}
                            <br />
                            {bookSpecialistText
                              ? bookSpecialistText.split(" ").slice(1).join(" ")
                              : "SPECIALIST"}
                          </Link>
                        ) : null}
                      </div>
                      <div
                        className="position-fixed"
                        onMouseEnter={() => setHoverForPackage(true)}
                        onClick={() => {
                          navigate("/packages");
                        }}
                        onMouseLeave={() => setHoverForPackage(false)}
                        style={{ margin: "auto", zIndex: "999999999999999" }}
                      >
                        {window.innerWidth <= 768 &&
                        scrollPositionNo >= 913 &&
                        scrollPositionNo <= 1582 ? (
                          <Link
                            to={"/packages"}
                            className={`split__screen--link `}
                            style={{
                              fontSize: "72px",
                              lineHeight: "86.2px",
                              color: landingPage?.props?.bookPackageTextColor
                                ? landingPage?.props?.bookPackageTextColor
                                : "white",
                              letterSpacing: "5px",
                            }}
                          >
                            {bookPackageText
                              ? bookPackageText.split(" ")[0]
                              : "BOOK"}{" "}
                            <br />
                            {bookPackageText
                              ? bookPackageText.split(" ").slice(1).join(" ")
                              : "PACKAGE"}
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : window.innerWidth <= 768 && scrollPositionNo <= 472 ? (
                  <h1
                    className={`mb-0 font-bold `}
                    style={{
                      position: "fixed",
                      zIndex: "999999999999999",
                      pointerEvents: "none",
                      fontSize:
                        landingPage?.props?.bannerCenteredText ||
                        fixedText2 === "SELF MAXIMIZED"
                          ? "84px"
                          : "72px",
                      letterSpacing:
                        landingPage?.props?.bannerCenteredText ||
                        fixedText2 === "SELF MAXIMIZED"
                          ? "2px"
                          : "2px",
                      lineHeight: "81px",
                      color: "white",
                    }}
                  >
                    {fixedText2}
                  </h1>
                ) : (
                  window.innerWidth <= 768 &&
                  scrollPositionNo >= 913 && (
                    <h1
                      className={`mb-0 font-bold `}
                      style={{
                        position: "fixed",
                        zIndex: "999999999999999",
                        pointerEvents: "none",
                        fontSize: "72px",
                        letterSpacing: "2px",
                        lineHeight: "81px",
                        color: "white",
                      }}
                    >
                      {fixedText}
                    </h1>
                  )
                )}
                {window.innerWidth >= 768 &&
                scrollPositionNo > 400 &&
                scrollPositionNo < 800 ? (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className={`position-fixed `}
                        onMouseEnter={() => setHoverForSpecialist(true)}
                        onMouseLeave={() => setHoverForSpecialist(false)}
                        style={{
                          left: 0,
                          margin: "auto",
                          minWidth: "50%",
                          zIndex: "999999999999999",
                        }}
                      >
                        <Link
                          to={"/categories"}
                          className={`split__screen--link mb-0 font-bold `}
                          style={{
                            fontSize: "136px",
                            lineHeight: "130px",
                            color: landingPage
                              ? landingPage?.props.bookSpecialistTextColor
                              : "white",
                            letterSpacing: "5px",
                          }}
                        >
                          <span className="d-block">
                            {bookSpecialistText
                              ? bookSpecialistText.split(" ")[0]
                              : "BOOK"}
                          </span>{" "}
                          <span>
                            {bookSpecialistText
                              ? bookSpecialistText.split(" ").slice(1).join(" ")
                              : "SPECIALIST"}
                          </span>
                        </Link>
                      </div>
                      <div
                        className={`position-fixed `}
                        onMouseEnter={() => setHoverForPackage(true)}
                        onMouseLeave={() => setHoverForPackage(false)}
                        onClick={() => {
                          navigate("/packages");
                        }}
                        style={{
                          right: 0,
                          minWidth: "50%",
                          margin: "auto",
                          zIndex: "999999999999999",
                        }}
                      >
                        <Link
                          to={"/packages"}
                          className={`split__screen--link `}
                          style={{
                            fontSize: "136px",
                            lineHeight: "130px",
                            letterSpacing: "5px",
                            color: landingPage?.props?.bookPackageTextColor
                              ? landingPage?.props?.bookPackageTextColor
                              : "white",
                          }}
                        >
                          <span className="d-block">
                            {bookPackageText
                              ? bookPackageText.split(" ")[0]
                              : "BOOK"}
                          </span>{" "}
                          <span>
                            {bookPackageText
                              ? bookPackageText.split(" ").slice(1).join(" ")
                              : "PACKAGE"}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </>
                ) : window.innerWidth >= 768 && scrollPositionNo <= 400 ? (
                  <h1
                    className={`mb-0 font-bold `}
                    style={{
                      position: "fixed",
                      zIndex: "999999999999999",
                      fontSize:
                        landingPage?.props?.bannerCenteredText ||
                        fixedText2 === "SELF MAXIMIZED"
                          ? "218.9px"
                          : "168px",
                      letterSpacing:
                        landingPage?.props?.bannerCenteredText ||
                        fixedText2 === "SELF MAXIMIZED"
                          ? "0px"
                          : "2px",
                      lineHeight: "190px",
                      pointerEvents: "none",
                      color: "white",
                    }}
                  >
                    {landingPage?.props?.bannerCenteredText
                      ? landingPage?.props?.bannerCenteredText
                      : fixedText2}{" "}
                  </h1>
                ) : (
                  window.innerWidth >= 768 &&
                  scrollPositionNo >= 400 && (
                    <h1
                      className={`mb-0 font-bold `}
                      style={{
                        position: "fixed",
                        zIndex: "999999999999999",
                        fontSize: "168px",
                        letterSpacing: "2px",
                        lineHeight: "130px",
                        pointerEvents: "none",
                        color: "white",
                      }}
                    >
                      {fixedText}{" "}
                    </h1>
                  )
                )}
              </div>

              <div className="get__started--btn pt-5 mt-5">
                <Link to={"categories"} className="btn book-btn mt-5">
                  GET STARTED
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SPLIT SCREEN */}
      <div className="page-content">
        <section className="py-0">
          <div className="container-fluid">
            <div className="row d-flex align-items-center ">
              <div
                className={`col-lg-6 px-0 col-md-6 col-sm-12 position-relative ${
                  hoverForSpecialist ? "black_background2" : "black_background"
                } `}
                ref={bookSpecialistRef}
              >
                <Link to={"/categories"} className="split__screen--link">
                  <div className="split-pane" style={bookSpecialist}>
                    <div className="split__text"></div>
                  </div>
                </Link>
              </div>
              <div
                className={`split-pane col-lg-6 col-md-6 col-sm-12 position-relative cursor_pointer ${
                  hoverForPackage ? "black_background2" : "black_background"
                }`}
                onClick={() => {
                  navigate("/packages");
                }}
                style={bookImages}
                ref={bookPackageRef}
              >
                <div className="split__text">
                  <Link to={"/packages"} className="split__screen--link"></Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-wrapper" ref={aboutRef} id="about-section">
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="about-description mb-0 text-uppercase mt-5 mt-lg-0">
                  {landingPage
                    ? landingPage?.props.text
                    : "An About Us page is a section on a website that provides information about a company, organization, or individual. It's an opportunity to tell your brand's story, share your vision, introduce team members, and outline your history, values, and achievements."}
                </h3>
              </div>
              <h6
                className="text-light mt-4 cursor_pointer"
                style={{ letterSpacing: "1px" }}
              >
                <Link to={"/about"} className="learn_mor text-light">
                  LEARN MORE
                </Link>
              </h6>
            </div>
          </div>
        </section>

        <section
          className="journal-wrapper pt-0 pb-4"
          ref={journalRef}
          id="journal-section"
        >
          <div className="container-fluid">
            <div className="row">
              {journalData.map((elem: any) => (
                <div className="col-lg-6 position-relative px-0" key={elem.id}>
                  <Link
                    to={`/journaldetails/${elem.id}`}
                    className="journal-box"
                    state={elem}
                    onClick={handleClick}
                  >
                    <div className="journal-thumb">
                      <div className="cs-image">
                        <img
                          src={elem.image}
                          alt="journal"
                          className="img-fluid w-100 main-img"
                          style={{ width: "auto", height: "500px" }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="journal-content">
                      <p className="journal-date-title mb-0 text-uppercase">
                        {moment(elem.onCreate).format("MMMM D, YYYY")}
                      </p>
                      <p className="mb-0 title-of-the-journal py-2">
                        <span className="d-block">{elem.title}</span>
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="revolution-in-holistic py-0">
          <div className="container-fluid left-right-space">
            <div className="row pb-3 pb-lg-5">
              <div className="col-lg-12 text-end">
                <Link to={"journal"} className="view-all" onClick={handleClick}>
                  VIEW ALL
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-wrapper"
          ref={specialistRef}
          id="specialist-section"
        >
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="about-description mb-0 text-uppercase mt-5 mt-lg-0">
                  A become a specialist page is a section on a website that
                  provides information about a company, organization, or
                  individual. It's an opportunity to tell your brand's story,
                  share your vision, introduce team members, and outline your
                  history, values, and achievements.
                </h3>
              </div>
              <div className="col-lg-12 text-end">
                <h6
                  className="text-light mt-4 cursor_pointer"
                  style={{ letterSpacing: "1px" }}
                >
                  <Link
                    to={"/specialistdetails"}
                    className="learn_mor text-light"
                  >
                    REGISTER
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </section>

        <div ref={faqRef} id="faq-section">
          <Faq />
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12 text-end">
                <h6
                  className="text-light mt-4 cursor_pointer"
                  style={{ letterSpacing: "1px" }}
                >
                  <Link to={"/categories"} className="learn_mor text-light">
                    GET STARTED
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <input
          className="next-btn"
          type="hidden"
          id="popUpModal"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        />
        <section
          className="pb-0 user-registration-flow-wrapper py-0"
          id="bigPopUp"
        >
          <div className="container-fluid left-right-space extra-space-container">
            <div className="row mt-5" id="modalPopUp">
              <div
                className="modal fade user__pop__wrapper p-0 "
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-fullscreen transform-none transition-none">
                  <div className="modal-content py-5 position-relative fullscreen-pop-up">
                    <div className="modal-body my-5 py-5 ps-5">
                      <img
                        src={
                          popUp?.props?.landingPopUpLogo
                            ? popUp?.props?.landingPopUpLogo
                            : LandingPopUpLogo
                        }
                        alt="user-logo"
                        className="img-fluid"
                        style={{ width: "180px", height: "180px" }}
                        loading="lazy"
                      />
                      <div className="">
                        <h3 className="mb-0 thanks-for-your-response thanks--response">
                          {popUp?.props?.greetingResponse
                            ? popUp?.props?.greetingResponse
                            : `THANKS FOR YOUR RESPONSE`}
                        </h3>
                        <p className="mb-0 you-will-receive">
                          {popUp?.props?.confirmationMessage
                            ? popUp?.props.confirmationMessage
                            : "You Will Receive A Confirmation Via Email."}
                        </p>
                      </div>
                    </div>
                    <div className="cancel__pop__icon" data-bs-dismiss="modal">
                      <i className="fa-solid fa-xmark text-white bg-dark"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Book;
